export enum SelectedPage {
    Home = "home",
    Shipper = "shipper",
    Carrier = "carrier",
    AboutUs ="aboutus",
    ContactUs = "contactus",
  }

  export interface BenefitType {
    icon: JSX.Element;
    title: string;
    description: string;
  }  

  export interface CarrierIcon {
    icon: string;
    title: string;
    description: string;
  }  

  export interface TeamDetails {
    name: string;
    description?: string;
    image: string;
  }

  export interface VehicleType {
    name: string;
    image: string;
  }  