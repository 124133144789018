import { motion } from "framer-motion";
import { SelectedPage ,TeamDetails } from "../Types/types";
import HText from "../Types/Htext";
import Varshit from "../../assets/Varshit.jpg";
import Nikhilesh from "../../assets/Nikhilesh.jpg";
import Preeti from "../../assets/Preeti.jpg";
import Team from "./Team";


const container = {
    hidden: {},
    visible: {
      transition: { staggerChildren: 0.2 },
    },
  };

type Props = {
    setSelectedPage: (value: SelectedPage) => void;
}

const teamdetails: Array<TeamDetails> = [
    {
      name: "Preeti Maan",
      description:
        "Co-founder , CEO",
      image:Preeti,  
    },
    {
      name: "Nikhilesh Vaishya ",
      description:
        "Co-founder , CTO",
      image:Nikhilesh
    },
    {
      name: "Varshit kalra",
      description:
      "Co-founder , COO",
      image:Varshit
    },
  ];  

const AboutUs = ({setSelectedPage}: Props) => {
  return (
    <section id="aboutus" className="mx-auto min-h-[80%] w-5/6 pt-28">
        <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.AboutUs)}
        >
                    {/* HEADER */}
        <motion.div
          className="md:my-5 w-full"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: +50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <HText>Meet the team</HText>
          <p className="my-5 text-2xl flex items-center justify-center text-center">
          Truxie, founded by three visionary friends under Prayas Logistics India Private Limited, aims to revolutionize and reimagine the trucking industry in India. Driven by a passion to redefine logistics, they are committed to making the transportation process efficient and seamless for shippers, carriers, and truck drivers alike.
          </p>
        </motion.div>
        
        <motion.div
          className="mt-5 justify-between md:flex"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
        >
          {teamdetails.map((benefit: TeamDetails) => (
            <Team
              key={benefit.name}
              name={benefit.name}
              description={benefit.description}
              image={benefit.image}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </motion.div>

        </motion.div>

    </section>
  )
}

export default AboutUs