import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
    title:string;
    description:string;
    name:string;
    type:string;
}

const SEO = ({title, description, name,type}:Props) => {
    return (
        <Helmet>
        { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta property="og:url" content="https://twitter.com/TruxieIndia" />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */ }
        { /* Facebook tags */ }
        <meta name="facebook:creator" content={name} />
        <meta name="facebook:card" content={type} />
        <meta property="og:url" content="https://www.facebook.com/people/Truxie-India/pfbid02RwNFD5QnSRSBRncR8nhQnsPHsCtJAypFytKLg1cpbe8JdDJ4NhmZ1sHUAyYWyCDUl/" />
        <meta name="facebook:description" content={description} />
        { /* End Facebook tags */ }
        { /* Instagram tags */ }
        <meta name="instagram:creator" content={name} />
        <meta name="instagram:card" content={type} />
        <meta property="og:url" content="https://www.instagram.com/truxieindia/" />
        <meta name="instagram:description" content={description} />
        { /* End Instagram tags */ }
        </Helmet>
        )
}

export default SEO