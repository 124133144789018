import { motion } from "framer-motion";
import HText from '../Types/Htext';
import { SelectedPage ,BenefitType } from '../Types/types';
import {
    TruckIcon
  } from "@heroicons/react/24/solid";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';  
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Shipperbenefits from './Shipperbenefit';

const container = {
    hidden: {},
    visible: {
      transition: { staggerChildren: 0.2 },
    },
  };

  type Props = {
    setSelectedPage: (value: SelectedPage) => void;
}  

  const benefits: Array<BenefitType> = [
    {
      icon: <LocalShippingIcon className="h-6 w-6" />,
      title: "Find trucks anytime",
      description:
        "Say goodbye to the hassle of dialing numerous brokers or truck owners just to locate a truck. With the Truxie platform, discover verified trucks for your goods within minutes, streamlining the process like never before.",
    },
    {
      icon: <ShareLocationIcon className="h-6 w-6" />,
      title: "Track shipment anytime",
      description:
        "Effortlessly monitor your goods with our GPS-based tracking system. Share real-time details with the consignee, receive instant notifications, and stay consistently informed about the precise whereabouts of your shipment.",
    },
    {
      icon: <CurrencyRupeeIcon className="h-6 w-6" />,
      title: "Higher Price Power",
      description:
      "Benefit from a competitive environment where multiple trucks vie for your load, granting you not only heightened negotiating leverage but also ensuring top-tier service quality and steadfast assurance of on-time delivery.",
    },
  ];  

const Shipper = ({setSelectedPage}: Props) => {
  return (
    <section id="shipper" className="mx-auto min-h-[80%] md:w-5/6 py-28">
    <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Shipper)}
    >
        {/* HEADER */}
        <motion.div
          className="md:my-5 md:w-full"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <HText>Web Application for Shipper</HText>
          <p className="my-5 w-full text-2xl flex items-center justify-center text-center">
          Need to ship goods? With Truxie, securing a truck is as simple as a click. Our rigorous verification process ensures peace of mind, with drivers thoroughly vetted through comprehensive background checks, including Aadhar and PAN verification.
          </p>
        </motion.div>
       
        <motion.div
          className="mt-5 justify-between gap-8 md:flex"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
        >
          {benefits.map((benefit: BenefitType) => (
            <Shipperbenefits
              key={benefit.title}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </motion.div>
      </motion.div>
    </section>    
  )
}

export default Shipper