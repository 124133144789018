import { SelectedPage } from '../Types/types';
import useMediaQuery from "../../hooks/useMediaQuery";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import ActionButton from '../Types/ActionButton';
import HeroMacMobileImage from '../../../src/assets/WebsiteHeroImagemac&mobile.png';

type Props = {
    setSelectedPage: (value: SelectedPage) => void;
}

const Home = ({setSelectedPage}: Props) => {
  const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");

  return (
    <section id="home" className="gap-16 bg-primary-95 py-36">
    <motion.div
        className="mx-auto w-5/6 items-center justify-center md:flex md:h-5/6"
        onViewportEnter={() => setSelectedPage(SelectedPage.Home)}
    >
        {/* MAIN HEADER */}
        <div className="z-10 mt-32 md:basis-4/5 pt-5">
             {/* HEADINGS */}
          <motion.div
            className="md:-mt-20"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="relative">
              <div className="before:absolute before:-top-20 before:-left-20 before:z-[-1] md:before:content-evolvetext">
                 <h1 className="text-5xl">Dependable <span className='font-black text-primary'>TRUCKS</span></h1>
                 <h1 className="text-5xl mt-5">Assured <span className='font-black pl-2 text-primary'>LOADS</span></h1>
                 <h1 className="text-5xl mt-5">Your One-Stop Solution </h1>
              </div>
            </div>

            <p className="mt-10 text-xl font-extrabold">
            Revolutionize the shipping industry with our cutting-edge digital freight marketplace, connecting shippers directly with a vast network of reliable trucking services for seamless transportation of goods.
            </p>
          </motion.div>
          {/* ACTIONS */}
          <motion.div
            className="mt-8 flex items-center gap-8"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <ActionButton setSelectedPage={setSelectedPage}>
              Sign Up
            </ActionButton>
            <AnchorLink
              className="text-lg font-bold text-primary underline hover:text-primary-55"
              onClick={() => setSelectedPage(SelectedPage.ContactUs)}
              href={`#${SelectedPage.ContactUs}`}
            >
              <p>Learn More</p>
            </AnchorLink>
        </motion.div>
        </div>
        {/* IMAGE */}
        <motion.div className="flex pt-10 basis-3/5 sm:w-full" 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 1 }}
                    variants={{
                      hidden: { opacity: 0, x: 50 },
                      visible: { opacity: 1, x: 0 },
                    }}
        >
         { <img className="hover:scale-[1.1]" alt="Macbook & Mobile app" src={HeroMacMobileImage} /> }
        </motion.div>

    </motion.div>
    </section>
  )
}

export default Home