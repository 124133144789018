import React from "react";

type Props = {
  children: React.ReactNode;
};

const HText = ({ children }: Props) => {
  return (
    <h2 className="text-5xl flex items-center justify-center text-center">{children}</h2>
  );
};

export default HText;