import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import { SelectedPage } from "./components/Types/types";
import Home from "./components/Home";
import Shipper from "./components/Shipper";
import Carrier from "./components/Carrier";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Vehicle from "./components/Vehicle";
import SEO from "./components/SEO";

function App() {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(
    SelectedPage.Home
  );
  const [isTopOfPage, setIsTopOfPage] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
        setSelectedPage(SelectedPage.Home);
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  
  return (
    <div className="app ">
      <SEO title="Truxie" 
           description="Truxie: digital freight platform connecting shippers with carriers"
           name="Prayas Logistics India Private Limited"
           type="Website" 
      />
      <Navbar 
        isTopOfPage={isTopOfPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}/>
      <Home setSelectedPage={setSelectedPage} />
      <Vehicle />
      <Shipper setSelectedPage={setSelectedPage} />  
      <Carrier setSelectedPage={setSelectedPage} /> 
      <AboutUs setSelectedPage={setSelectedPage} /> 
      <ContactUs setSelectedPage={setSelectedPage} /> 
      <Footer />
    </div>
  
  );
}

export default App;
