import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/Logo.png"
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin
  } from "@fortawesome/free-brands-svg-icons";
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';  

const Footer = () => {
  return (
    <footer className="bg-primary-95 py-16 h-29">
      <div className="justify-content mx-auto w-5/6 gap-16 md:flex">
        <div className="mt-16 basis-1/2 md:mt-0 sm:justify-center xs:w-full">
            {/* <div className="w-full md:w-3/5 bg-primary text-white rounded-lg"> */}
                <div className="flex pl-[100px]">
                      {/* <h1 >Truxie</h1>
                </div>
                <div className="flex justify-center">
                      <p>Make Trucking Simpler</p> */}
                      { <img className="h-[200px]" alt="Logo" src={Logo} /> }
                {/* </div> */}
            </div>
          <p className="my-5">
          Truxie is a digital freight market place where shippers  can connect with truck owners for transportation of goods.
          </p>
          
        </div>
        <div className="mt-16 basis-1/4 md:mt-0">
          <p className="my-5">About Us</p>
          <p className="my-5">Terms & Conditions</p>
          <p>Privacy Policy</p>
        </div>
        <div className="mt-16 basis-1/4 md:mt-0">
          <h4 className="font-bold text-3xl">Contact Us</h4>
          <p className="my-5 text-xl">Prayas Logistics India Private Limited</p>
          <p className="my-5"><RoomIcon/> 305-G,First floor,Mukund Nagar, Ghaziabad, U.P - 201001</p>
          <p className="my-5"><PhoneIcon/> (+91) 9986894005</p>
          <p><EmailIcon/> contact@truxie.in</p>
        </div>
        <div className="flex justify-center">
        <a href="https://www.linkedin.com/in/truxie-your-digital-freight-partner-596253279/"
            className="p-3">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100093277804791/"
            className="p-3">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://twitter.com/TruxieIndia" className="p-3">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="https://www.instagram.com/truxieindia/"
            className="p-3">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>

        </div>
      </div>
      <p className="flex justify-center pt-5">© Prayas Logistics India Private Limited All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
