import React, { useState} from "react";
import { motion } from "framer-motion";
import { SelectedPage } from "../Types/types";
import HText from "../Types/Htext";
import { useForm } from "react-hook-form";
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import emailjs from '@emailjs/browser';

type Props = {
    setSelectedPage: (value: SelectedPage) => void;
}

const ContactUs = ({setSelectedPage}: Props) => {
  const [successMsg, setSuccessMsg] = useState("");
  const sendEmail = (e: any) => {
    e.preventDefault();
    emailjs.sendForm('service_9yhcybo', 'template_u6rrio7', e.target, 'BZdOiWThxB9Px7Ydf')
      .then((result) => {
          console.log(result.text);
          setSuccessMsg("Form submitted successfully.");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  const inputStyles = `mb-5 w-4/5 rounded-lg bg-primary-95
  px-5 py-3 placeholder-gray text-black`;

  return (
    <section id="contactus" className="mx-auto w-5/6 py-28">
        <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.ContactUs)}
      >
        {/* HEADER */}
        <motion.div
          className="md:w-full"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <HText>Contact Us</HText>
        </motion.div>

        {/* FORM AND IMAGE */}
        <div className="mt-10 justify-between gap-8 md:flex ">
          <motion.div
            className="pt-1"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <form
             onSubmit={sendEmail}
            >
              <input
                className={inputStyles}
                name="name"
                maxLength={100}
                type="text"
                placeholder="NAME"
                required
                autoComplete="off"
                
              />
              <input
                className={inputStyles}
                type="email"
                name="email"
                placeholder="EMAIL"
                autoComplete="off"
                required
              />
              <textarea
                className={inputStyles}
                name="message"
                placeholder="MESSAGE"
                rows={4}
                cols={50}
                required
                maxLength={2000}
                autoComplete="off"
                
              />
              <button
                type="submit"
                className="mt-5 rounded-lg bg-primary-95 text-black hover:bg-primary hover:text-white px-20 py-3 transition duration-500 sm:justify-center"
              >
                SUBMIT
              </button>
              {successMsg && <p className="success-msg text-green-900">{successMsg}</p>}
            </form>
          </motion.div>
          <motion.div
            className="mt-20 basis-4/5 md:mt-0 bg-primary-95 rounded-[14px] drop-shadow text-black"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <h1 className="text-3xl flex justify-center p-10"> Prayas Logistics India Private Limited</h1>
            <p className="text-xl flex justify-center p-5"><RoomIcon className="pt-2" />305-G, First Floor, Mukund Nagar, Ghaziabad, U.P - 201001</p>
            <p className="text-xl flex justify-center p-5"><PhoneIcon className="pt-2"/>(+91) 9986894005</p>
            <p className="text-xl flex justify-center p-5"><EmailIcon className="pt-2"/>contact@truxie.in</p>
          </motion.div>
          </div>
        
      </motion.div>

    </section>
  )
}

export default ContactUs