import { SelectedPage } from '../Types/types';
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const childVariant = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
};

type Props = {
  name: string;
  description?: string;
  image: string;
  setSelectedPage: (value: SelectedPage) => void;
};

const Team = ({ name, image, description, setSelectedPage }: Props) => {
  return (
    <div
      // variants={childVariant}
      className="mt-5 rounded-md border-2 border-gray-100 px-5 py-16 text-center"
    >
      <div className="mb-4 flex justify-center">
        <div className="w-[300px] h-[400px]">
        <img alt={`${image}`} src={image} />
        </div>
      </div>

      <h4 className="font-bold text-2xl">{name}</h4>
      <p className="my-3 text-xl">{description}</p>
      <AnchorLink
        className="text-xl font-bold text-primary underline hover:text-primary-45"
        onClick={() => setSelectedPage(SelectedPage.ContactUs)}
        href={`#${SelectedPage.ContactUs}`}
      >
        <p>Learn More</p>
      </AnchorLink>
    </div>
  );
};

export default Team;
