import { motion } from 'framer-motion';
import { SelectedPage , BenefitType} from '../Types/types';
import HText from '../Types/Htext';
import {
    UserGroupIcon
  } from "@heroicons/react/24/solid";
import GavelIcon from '@mui/icons-material/Gavel';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PaymentsIcon from '@mui/icons-material/Payments';
import Carrierbenefits from './Carrierbenefits';

const container = {
    hidden: {},
    visible: {
      transition: { staggerChildren: 0.2 },
    },
  };

type Props = {
    setSelectedPage: (value: SelectedPage) => void;
}

const benefits: Array<BenefitType> = [
    {
      icon: <GavelIcon className="h-6 w-6" />,
      title: "Bid on your favourite loads",
      description:
        "Owning a truck demands dedication and hard work. With Truxie, take control of your business by bidding on preferred loads, selecting optimal routes, and maximizing your vehicle's efficiency to its fullest potential.",
    },
    {
      icon: <WorkHistoryIcon className="h-6 w-6" />,
      title: "Flexible scheduling",
      description:
        "Been on the road for a few days? Utilize our 'Search Loads' feature to discover your preferred loads and secure a return trip home hassle-free.",
    },
    {
      icon: <PaymentsIcon className="h-6 w-6" />,
      title: "Instant Payment",
      description:
      "No need to wait for days to receive your payment. With Truxie Pay, upload your Proof of Delivery and get paid on the same day, ensuring prompt compensation for your services.",
    },
  ];  

const Carrier = ({setSelectedPage}: Props) => {
  return (
    <section id="carrier" className="mx-auto min-h-[60%] md:w-5/6 py-28 bg-primary-95 w-full">
        <motion.div
        onViewportEnter={() => setSelectedPage(SelectedPage.Carrier)}
        >
            {/* HEADER */}
        <motion.div
          className="md:my-5 md:w-full"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <HText>Mobile application for Carriers</HText>
          <p className="my-5 text-2xl flex items-center justify-center text-center">
          Empower your trucking business with the Truxie app for carriers, effortlessly discovering dependable loads to your preferred destinations at the tap of a button. Seamlessly bid on coveted loads and keep your fleet in motion. Experience the convenience of Truxie Pay, ensuring swift payment on the very same day.
          </p>
        </motion.div>
        <motion.div
          className="mt-5 justify-between gap-8 md:flex"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
        >
          {benefits.map((benefit: BenefitType) => (
            <Carrierbenefits
              key={benefit.title}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
              setSelectedPage={setSelectedPage}
            />
          ))}
        </motion.div>

        </motion.div>

    </section>
)
}

export default Carrier