import { motion } from "framer-motion";
import TataAce from "../../assets/TataAce.jpg";
import BoleroPickup from "../../assets/BoleroPickup2.jpeg";
import Tata407 from "../../assets/Tata407.png";
import Eicher14foot from "../../assets/Eicher14footi.jpeg";
import Eicher17foot from "../../assets/Eicher17foot.jpeg";
import Eicher19foot from "../../assets/Eicher19foot.jpeg";
import { VehicleType } from '../Types/types';
import useMediaQuery from "../../hooks/useMediaQuery";

type Props = {}

const vehicles : Array<VehicleType> = [
    {name: "Chota Haathi",
     image: TataAce
    },
    {name: "Bolero Pickup",
     image: BoleroPickup
    },
    {name: "Tata 407",
     image: Tata407
    },
    {name: "Eicher 14 feet",
     image: Eicher14foot
    },
    {name: "Eicher 17 feet",
    image: Eicher17foot
   },
   {name: "Eicher 19 feet",
   image: Eicher19foot
  }
];

const Vehicles = (props: Props) => {
    const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");

  return (
    <section id="vehicle" className="mx-auto h-20 w-full py-28">
        <motion.div
            className="flex justify-between items-center px-20"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 2 }}
            variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
            }}    
            >
                {isAboveMediumScreens && vehicles.map((vehicle) => 
                        <div key={vehicle.name}>
                        <img alt={vehicle.image} src={vehicle.image} className="h-20 w-20 hover:scale-[3] rounded-full"></img>
                        <p className="text-xl">{vehicle.name}</p>
                        </div>
                )}
        </motion.div>
    </section>    
  )
}

export default Vehicles